<template>
    <div>
        <!-- Add Business Model-->
        <b-modal id="headre_add_business" title="Add Business" no-stacking hide-footer>
            <div class="modal-area">
                <ul class="list-unstyled payment-list mb-0">
                    <li class="border rounded p-3" v-b-modal.headre_add_single>
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="22.4 1.4 100 100" style="enable-background:new 22.4 1.4 100 100;" xml:space="preserve">
                                <path id="XMLID_2_" d="M280.2,32.4c-0.3-0.3-0.8-0.5-1.3-0.5h-21.8V7c0-0.5-0.2-1-0.5-1.4l-0.1-0.1c-0.4-0.4-1-0.6-1.5-0.6h-43.6
                                    c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,1-0.6,1.5v24.8h-21.6c0,0,0,0,0,0c-1,0-1.8,0.8-1.8,1.8v45.1c0,0.7,0.6,1.2,1.2,1.2h92.5
                                    c0.7,0,1.2-0.6,1.2-1.2V33.6C280.7,33.2,280.5,32.7,280.2,32.4z M223.4,22.6h8.6v-8.6h4v8.6h8.6v4H236v8.6h-4v-8.6h-8.6V22.6z
                                    M200.5,66.8c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h0h7.2c0.1,0,0.2,0.1,0.2,0.2V66.8z
                                    M200.5,52.7c0,0.1-0.1,0.2-0.2,0.2h-7.3c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h0h7.2c0.1,0,0.2,0.1,0.2,0.2V52.7z
                                    M212.9,66.8c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V66.8z
                                    M212.9,52.7c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V52.7z
                                    M224.5,66.8c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h0h7.2c0.1,0,0.2,0.1,0.2,0.2V66.8z
                                    M224.5,52.7c0,0.1-0.1,0.2-0.2,0.2h-7.3c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h0h7.2c0.1,0,0.2,0.1,0.2,0.2V52.7z
                                    M236.9,66.8c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V66.8z
                                    M236.9,52.7c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V52.7z
                                    M249.3,66.8c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V66.8z
                                    M249.3,52.7c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V52.7z
                                    M260.9,66.8c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V66.8z
                                    M260.9,52.7c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V52.7z
                                    M273.3,66.8c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V66.8z
                                    M273.3,52.7c0,0.1-0.1,0.2-0.2,0.2h-7.2c-0.1,0-0.2-0.1-0.2-0.2v-7.2c0-0.1,0.1-0.2,0.2-0.2h7.2c0.1,0,0.2,0.1,0.2,0.2V52.7z"/>
                                <path id="XMLID_32_" d="M119.4,40.4c-0.3-0.3-0.8-0.5-1.3-0.5H96.3V10c0-0.5-0.2-1-0.5-1.4l-0.1-0.1c-0.4-0.4-1-0.6-1.5-0.6H50.6
                                    c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,1-0.6,1.5v29.8H26.7c0,0,0,0,0,0c-1,0-1.8,0.8-1.8,1.8v50.1c0,0.7,0.6,1.2,1.2,1.2h92.5
                                    c0.7,0,1.2-0.6,1.2-1.2V41.6C119.9,41.2,119.8,40.7,119.4,40.4z M117.5,42.3v48.2H96.3h-48h-21V42.3l23.4,0v-32h43.1v31.9
                                    L117.5,42.3z"/>
                                <g id="XMLID_19_">
                                    <path id="XMLID_24_" d="M63.6,56.3h-7.2c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C63.8,56.4,63.7,56.3,63.6,56.3z"/>
                                    <path id="XMLID_37_" d="M75.9,56.3h-7.2c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C76.1,56.4,76.1,56.3,75.9,56.3z"/>
                                    <path id="XMLID_38_" d="M75.9,70.3h-7.2l0,0c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C76.1,70.4,76.1,70.3,75.9,70.3z"/>
                                    <path id="XMLID_39_" d="M63.6,70.3h-7.2l0,0c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C63.8,70.4,63.7,70.3,63.6,70.3z"/>
                                    <path id="XMLID_40_" d="M51.2,70.3H44l0,0c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C51.4,70.4,51.3,70.3,51.2,70.3z"/>
                                    <path id="XMLID_41_" d="M51.2,56.3H44h0c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.3c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C51.4,56.4,51.3,56.3,51.2,56.3z"/>
                                    <path id="XMLID_42_" d="M88.6,56.3h-7.2c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C88.8,56.4,88.7,56.3,88.6,56.3z"/>
                                    <path id="XMLID_43_" d="M100.9,56.3h-7.2c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C101.1,56.4,101.1,56.3,100.9,56.3z"/>
                                    <path id="XMLID_44_" d="M100.9,70.3h-7.2l0,0c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C101.1,70.4,101.1,70.3,100.9,70.3z"/>
                                    <path id="XMLID_45_" d="M88.6,70.3h-7.2l0,0c-0.1,0-0.2,0.1-0.2,0.2v7.2c0,0.1,0.1,0.2,0.2,0.2h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2
                                        C88.8,70.4,88.7,70.3,88.6,70.3z"/>
                                </g>
                                <path id="XMLID_22_" d="M89.4,13.2H55.5c-1.1,0-2,0.9-2,2v25.1c0,1.1,0.9,2,2,2h33.9c1.1,0,2-0.9,2-2V15.1
                                    C91.3,14,90.5,13.2,89.4,13.2z M83,30.4h-8.6V39h-4v-8.6h-8.6v-4h8.6v-8.6h4v8.6H83V30.4z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Add Single Business</h6></a>
                    </li>
                    <li class="border rounded p-3" v-b-modal.headre_add_sub_business>
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1_">
                                    <path id="XMLID_24_" class="st0" d="M10.8,57.7h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C10.6,57.7,10.6,57.7,10.8,57.7z"/>
                                    <path id="XMLID_25_" class="st0" d="M10.8,66.5h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C10.6,66.4,10.6,66.5,10.8,66.5z"/>
                                    <path id="XMLID_26_" class="st0" d="M10.8,75.2h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C10.6,75.1,10.6,75.2,10.8,75.2z"/>
                                    <path id="XMLID_32_" class="st0" d="M97.5,14.6c0-0.5-0.2-1-0.5-1.4l-0.1-0.1c-0.4-0.4-1-0.6-1.5-0.6H28.1c-0.6,0-1.1,0.2-1.5,0.6
                                        c-0.4,0.4-0.6,1-0.6,1.5v24.8H4.3c0,0,0,0,0,0c-1,0-1.8,0.8-1.8,1.8v45.1c0,0.7,0.6,1.2,1.2,1.2h92.5c0.7,0,1.2-0.6,1.2-1.2V16.2
                                        L97.5,14.6z M25.9,85.1H5V41.9h21V85.1z M95,85.1H73.9h-2.5H28.4V14.9h66.8"/>
                                </g>
                                <path id="XMLID_22_" class="st0" d="M77.9,20.7H44.1c-1.1,0-2,0.9-2,2v25.1c0,1.1,0.9,2,2,2h33.9c1.1,0,2-0.9,2-2V22.7
                                    C79.9,21.6,79,20.8,77.9,20.7z M71.6,38H63v8.6h-4V38h-8.6v-4H59v-8.6h4V34h8.6V38z"/>
                                <path id="XMLID_20_" class="st0" d="M57.3,63.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2c-0.1,0-0.2,0.1-0.2,0.2
                                    v7.2C57.1,63.4,57.2,63.5,57.3,63.5z"/>
                                <path id="XMLID_23_" class="st0" d="M69.7,63.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2c-0.1,0-0.2,0.1-0.2,0.2
                                    v7.2C69.5,63.4,69.6,63.5,69.7,63.5z"/>
                                <path id="XMLID_27_" class="st0" d="M69.7,77.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2l0,0
                                    c-0.1,0-0.2,0.1-0.2,0.2v7.2C69.5,77.5,69.6,77.5,69.7,77.5z"/>
                                <path id="XMLID_28_" class="st0" d="M57.3,77.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2l0,0
                                    c-0.1,0-0.2,0.1-0.2,0.2v7.2C57.1,77.5,57.2,77.5,57.3,77.5z"/>
                                <path id="XMLID_36_" class="st0" d="M44.9,77.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2l0,0
                                    c-0.1,0-0.2,0.1-0.2,0.2v7.2C44.7,77.5,44.8,77.5,44.9,77.5z"/>
                                <path id="XMLID_37_" class="st0" d="M44.9,63.5h7.3c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2h0
                                    c-0.1,0-0.2,0.1-0.2,0.2v7.2C44.7,63.4,44.8,63.5,44.9,63.5z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Add Sub Business</h6></a>
                    </li>
                    <li class="border rounded p-3" v-b-modal.headre_upload_subbusiness>
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1_">
                                    <path id="XMLID_19_" class="st0" d="M79.4,75.2h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C79.2,75.1,79.3,75.2,79.4,75.2z"/>
                                    <path id="XMLID_20_" class="st0" d="M46.3,60.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2c-0.1,0-0.2,0.1-0.2,0.2
                                        v7.2C46.1,60.4,46.2,60.5,46.3,60.5z"/>
                                    <path id="XMLID_23_" class="st0" d="M58.7,60.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2c-0.1,0-0.2,0.1-0.2,0.2
                                        v7.2C58.5,60.4,58.6,60.5,58.7,60.5z"/>
                                    <path id="XMLID_24_" class="st0" d="M10.8,57.7h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C10.6,57.7,10.6,57.7,10.8,57.7z"/>
                                    <path id="XMLID_25_" class="st0" d="M10.8,66.5h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C10.6,66.4,10.6,66.5,10.8,66.5z"/>
                                    <path id="XMLID_26_" class="st0" d="M10.8,75.2h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C10.6,75.1,10.6,75.2,10.8,75.2z"/>
                                    <path id="XMLID_27_" class="st0" d="M58.7,74.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2l0,0
                                        c-0.1,0-0.2,0.1-0.2,0.2v7.2C58.5,74.5,58.6,74.5,58.7,74.5z"/>
                                    <path id="XMLID_28_" class="st0" d="M46.3,74.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2l0,0
                                        c-0.1,0-0.2,0.1-0.2,0.2v7.2C46.1,74.5,46.2,74.5,46.3,74.5z"/>
                                    <path id="XMLID_29_" class="st0" d="M79.4,66.5h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7c-0.1,0-0.2,0.1-0.2,0.1v4
                                        C79.2,66.4,79.3,66.5,79.4,66.5z"/>
                                    <path id="XMLID_32_" class="st0" d="M97,40c-0.3-0.3-0.8-0.5-1.3-0.5H73.9V14.6c0-0.5-0.2-1-0.5-1.4l-0.1-0.1
                                        c-0.4-0.4-1-0.6-1.5-0.6H28.1c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,1-0.6,1.5v24.8H4.3c0,0,0,0,0,0c-1,0-1.8,0.8-1.8,1.8v45.1
                                        c0,0.7,0.6,1.2,1.2,1.2h92.5c0.7,0,1.2-0.6,1.2-1.2V41.2C97.5,40.8,97.3,40.3,97,40z M25.9,85.1H5V41.9h21V85.1z M71.4,85.1H28.4
                                        V14.9h43.1V85.1z M95,85.1H73.9V41.9H95V85.1z"/>
                                    <path id="XMLID_35_" class="st0" d="M79.4,57.7h9.7c0.1,0,0.2-0.1,0.2-0.1v-4c0-0.1-0.1-0.1-0.2-0.1h-9.7v0c-0.1,0-0.2,0.1-0.2,0.1
                                        v4C79.2,57.7,79.3,57.7,79.4,57.7z"/>
                                    <path id="XMLID_36_" class="st0" d="M33.9,74.5h7.2c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2l0,0
                                        c-0.1,0-0.2,0.1-0.2,0.2v7.2C33.7,74.5,33.8,74.5,33.9,74.5z"/>
                                    <path id="XMLID_37_" class="st0" d="M33.9,60.5h7.3c0.1,0,0.2-0.1,0.2-0.2v-7.2c0-0.1-0.1-0.2-0.2-0.2h-7.2h0
                                        c-0.1,0-0.2,0.1-0.2,0.2v7.2C33.7,60.4,33.8,60.5,33.9,60.5z"/>
                                </g>
                                <path id="XMLID_22_" class="st0" d="M66.9,17.7H33.1c-1.1,0-2,0.9-2,2v25.1c0,1.1,0.9,2,2,2h33.9c1.1,0,2-0.9,2-2V19.7
                                    C68.9,18.6,68,17.8,66.9,17.7z M60.6,35H52v8.6h-4V35h-8.6v-4H48v-8.6h4V31h8.6V35z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Upload Sub Businesses</h6></a>
                    </li>
                </ul>
            </div>
        </b-modal>

        <!-- Add Single Business -->
        <b-modal id="headre_add_single" title="Add Single Business" hide-footer>
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(singleBusinessForm)" id="single-form">
                        <li>
                            <ValidationProvider name="businessName" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.businessName,'success':singleBusinessDatas.businessName}">
                                    <span class="float-label">
                                        <input type="text" v-model="singleBusinessDatas.businessName" class="form-control" id="businessName" placeholder="Business Name*">
                                        <label for="businessName" class="control-label">Business Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.businessName"></i>
                                        <i class="fa fa-check" v-if="singleBusinessDatas.businessName"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="owner" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.owner,'success':singleBusinessDatas.owner}">
                                    <span class="float-label">
                                        <input type="text" v-model="singleBusinessDatas.owner" class="form-control" id="owner" placeholder="Owner*">
                                        <label for="owner" class="control-label">Owner*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.owner"></i>
                                        <i class="fa fa-check" v-if="singleBusinessDatas.owner"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="businessEmail" rules="required|email" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.businessEmail || errors[0],'success':singleBusinessDatas.businessEmail && !errors[0]}">
                                    <span class="float-label">
                                        <input type="email" v-model="singleBusinessDatas.businessEmail" class="form-control" name="email1" id="businessEmail" placeholder="Email Address*">
                                        <label for="businessEmail" class="control-label">Email Address*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.businessEmail || errors[0] "></i>
                                        <i class="fa fa-check" v-if="singleBusinessDatas.businessEmail && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <div class="phone-with-country d-flex justify-content-between align-items-center">
                                <div class="select-country">
                                    <ValidationProvider name="technicalcountry" rules="required" v-slot="{ errors }">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors.length && !singleBusinessDatas.country,'success':singleBusinessDatas.country}">
                                            <span class="float-label">
                                            <label for="technicalcountry">Select Country*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="singleBusinessDatas.country"   
                                                    :show-labels="false" 
                                                    :options="countrysOptions"
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name" >
                                                    <template slot="option" slot-scope="props">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                                            <span>{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                                <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.country"></i>
                                                <i class="fa fa-check" v-if="singleBusinessDatas.country"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="add-number">
                                    <ValidationProvider name="phoneNumber" rules="required" v-slot="{ errors}">
                                        <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.phoneNumber,'success':singleBusinessDatas.phoneNumber}">
                                            <span class="float-label">
                                                <input type="text" v-model="singleBusinessDatas.phoneNumber" class="form-control" id="phoneNumber" placeholder="Phone Number*">
                                                <label for="phoneNumber" class="control-label">Phone Number*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.phoneNumber"></i>
                                                <i class="fa fa-check" v-if="singleBusinessDatas.phoneNumber"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </li>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg">Add Single Business</b-button>
                            <b-button @click="$bvModal.hide('headre_add_single')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>

        <!-- Add Sub Business -->
        <b-modal id="headre_add_sub_business" title="Add Sub Business" hide-footer>
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(subBusinessSubmit)" id="subbusiness-form">
                        <li>
                            <ValidationProvider name="subbusinessName" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !subBisnessData.subbusinessName,'success':subBisnessData.subbusinessName}">
                                    <span class="float-label">
                                        <input type="text" v-model="subBisnessData.subbusinessName" class="form-control" id="subbusinessName" placeholder="Business Name*">
                                        <label for="subbusinessName" class="control-label">Business Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !subBisnessData.subbusinessName"></i>
                                        <i class="fa fa-check" v-if="subBisnessData.subbusinessName"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="businessName" rules="required" v-slot="{ errors }">
                                <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors.length && !subBisnessData.parentBusiness,'success':subBisnessData.parentBusiness}">
                                    <span class="float-label">
                                    <label for="businessName">Select Business Name*</label>
                                        <multiselect 
                                            placeholder=""
                                            v-model="subBisnessData.parentBusiness" 
                                            :options="businessnameOptions" 
                                            :show-labels="false"
                                            label="name" 
                                            track-by="id">
                                            <template slot="option" slot-scope="props">
                                                <div class="option-title">
                                                    <span>{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                        <i class="fa fa-times" v-if="errors.length && !subBisnessData.parentBusiness"></i>
                                        <i class="fa fa-check" v-if="subBisnessData.parentBusiness"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <strong> Sub Business Type</strong>
                            <div class="form-group radio-check-grp">
                                <label class="fancy-radio mb-0">
                                    <input name="businessType" value="subsidiary" type="radio" checked>
                                    <span><i></i>Subsidiary</span>
                                </label>
                                <label class="fancy-radio mb-0">
                                    <input name="businessType" value="Branch" type="radio">
                                    <span><i></i>Branch</span>
                                </label>
                                <label class="fancy-radio mb-0">
                                    <input name="businessType" value="Client" type="radio">
                                    <span><i></i>Client</span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <ValidationProvider name="subOwner" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !subBisnessData.subOwner,'success':subBisnessData.subOwner}">
                                    <span class="float-label">
                                        <input type="text" v-model="subBisnessData.subOwner" class="form-control" id="subOwner" placeholder="Owner*">
                                        <label for="subOwner" class="control-label">Owner*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !subBisnessData.subOwner"></i>
                                        <i class="fa fa-check" v-if="subBisnessData.subOwner"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="subbusinessEmail" rules="required|email" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !subBisnessData.subbusinessEmail || errors[0],'success':subBisnessData.subbusinessEmail && !errors[0]}">
                                    <span class="float-label">
                                        <input type="email" v-model="subBisnessData.subbusinessEmail" class="form-control" name="email1" id="subbusinessEmail" placeholder="Email Address*">
                                        <label for="subbusinessEmail" class="control-label">Email Address*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !subBisnessData.subbusinessEmail || errors[0] "></i>
                                        <i class="fa fa-check" v-if="subBisnessData.subbusinessEmail && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <div class="phone-with-country d-flex justify-content-between align-items-center">
                                <div class="select-country">
                                    <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors.length && !subBisnessData.country,'success':subBisnessData.country}">
                                            <span class="float-label">
                                            <label for="country">Select Country*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="subBisnessData.country"   
                                                    :show-labels="false" 
                                                    :options="countrysOptions"
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name" >
                                                    <template slot="option" slot-scope="props">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                                            <span>{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                                <i class="fa fa-times" v-if="errors.length && !subBisnessData.country"></i>
                                                <i class="fa fa-check" v-if="subBisnessData.country"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="add-number">
                                    <ValidationProvider name="subphoneNumber" rules="required" v-slot="{ errors}">
                                        <div class="form-group" :class="{'errors': errors.length && !subBisnessData.subphoneNumber,'success':subBisnessData.subphoneNumber}">
                                            <span class="float-label">
                                                <input type="text" v-model="subBisnessData.subphoneNumber" class="form-control" id="subphoneNumber" placeholder="Phone Number*">
                                                <label for="subphoneNumber" class="control-label">Phone Number*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors.length && !subBisnessData.subphoneNumber"></i>
                                                <i class="fa fa-check" v-if="subBisnessData.subphoneNumber"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </li>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg">Add Sub Business</b-button>
                            <b-button @click="$bvModal.hide('headre_add_sub_business')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>

        <!-- Upload Sub Business -->
        <b-modal id="headre_upload_subbusiness" title="Upload Sub Business" no-stacking hide-footer>
            <div class="modal-area">
                <ul class="list-unstyled payment-list mb-0">
                    <li class="border rounded p-3" v-b-modal.headre_upload_subsidiaries>
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <polygon points="17.6,65.8 46.3,65.8 46.3,76 53.9,76 53.9,65.8 82.3,65.8 82.3,77.1 89.4,77.1 89.4,65.7 89.4,63.2 89.4,58.7 
                                    53.9,58.7 53.9,49.1 46.2,49.1 46.2,58.8 10.5,58.8 10.5,63.4 10.5,65.8 10.5,77.2 17.6,77.2 "/>
                                <path d="M50.2,81.5c-4.4,0-8,3.5-8,8s3.5,8,8,8c4.5,0,8-3.5,8-8S54.5,81.5,50.2,81.5z"/>
                                <path d="M85.9,81.5c-4.4,0-8,3.5-8,8s3.5,8,8,8c4.5,0,8-3.5,8-8S90.3,81.5,85.9,81.5z"/>
                                <path d="M14.1,81.5c-4.4,0-8,3.5-8,8s3.5,8,8,8c4.5,0,8-3.5,8-8S18.5,81.5,14.1,81.5z"/>
                                <path d="M54.1,28.8v2.7c0,0.5-0.2,1.1-0.6,1.5c-0.3,0.4-0.9,0.6-1.5,0.6h-4c-0.5,0-1.1-0.2-1.5-0.6c-0.4-0.3-0.6-0.9-0.6-1.5v-2.9
                                    c-3.8-0.7-7.6-2-11.2-3.6c-2.8-1.2-5.4-2.7-8.1-4.4v17.7c0,0.7,0.2,1.4,0.7,1.9c0.5,0.4,1.2,0.7,1.9,0.7h41.6
                                    c0.7,0,1.4-0.2,1.9-0.7c0.4-0.5,0.7-1.2,0.7-1.9V20.8c-2.6,1.6-5.2,3-8,4.3C61.9,26.8,58,27.9,54.1,28.8z"/>
                                <path d="M72.7,8.6c-0.5-0.4-1.2-0.7-1.9-0.7h-12V4.4c0-0.5-0.2-1.1-0.5-1.4c-0.4-0.3-0.9-0.5-1.4-0.5H43.1c-0.5,0-1.1,0.2-1.4,0.5
                                    c-0.3,0.4-0.5,0.9-0.5,1.4v3.4h-12c-0.6,0-1.4,0.3-1.9,0.7c-0.4,0.5-0.7,1.2-0.7,1.9v6.3c3.1,2.1,6.3,3.9,9.6,5.4
                                    c3.1,1.4,6.4,2.6,9.7,3.3v-2.6c0-0.5,0.2-1.1,0.6-1.5c0.3-0.4,0.9-0.6,1.5-0.6h4c0.5,0,1.1,0.2,1.5,0.6c0.4,0.3,0.6,0.9,0.6,1.5
                                    v2.4c3.5-0.7,6.8-1.8,10-3.3c3.3-1.4,6.4-3.2,9.4-5.2v-6.4C73.5,9.7,73.2,9.1,72.7,8.6z M55.7,7.8H44.3V5.4c0-0.1,0.1-0.2,0.1-0.2
                                    c0.1-0.1,0.1-0.1,0.2-0.1h10.9l0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.2V7.8z"/>
                                <path d="M48.2,31.1h3.5c0.1,0,0.1,0,0.1-0.1l0.1-0.1v-7.3c0-0.1,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1h-3.5c-0.1,0-0.1,0-0.1,0.1
                                    L48,23.6v7.3c0,0.1,0,0.1,0.1,0.1L48.2,31.1z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Upload Subsidiaries</h6></a>
                    </li>
                    <li class="border rounded p-3" v-b-modal.headre_upload_branches>
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <path class="st1 light-color" d="M66,79.4c-3.8,0-6.7-1-8.6-3.2c-2.1-2.5-3.2-6.3-3.4-11.8v-12v-5v-12c0.2-5.4,1.3-9.3,3.4-11.8
                                    c1.9-2.2,4.7-3.3,8.6-3.2v5.6l9.9-9.8L66,6.2v6.4c-6.3,0-11.3,2-14.7,6.1c-0.5,0.6-0.9,1.1-1.3,1.9c-0.4-0.5-0.7-1.1-1.2-1.5
                                    c-3.5-4-8.4-6.1-14.7-6.1V6.4l-9.9,10.1l9.9,9.8v-5.6c3.8,0,6.7,1,8.6,3.2c2.1,2.5,3.2,6.1,3.4,11.5v12.1v5v12
                                    c-0.2,5.4-1.3,9.1-3.4,11.5c-1.9,2.2-4.8,3.3-8.6,3.2v-5.5l-9.9,9.8L34,93.6v-6.4c6.3,0,11.2-2,14.7-6.1c0.4-0.5,0.8-1,1.2-1.5
                                    c0.4,0.6,0.8,1.2,1.3,1.9c3.5,4,8.4,6.1,14.7,6.1v6.3l9.9-10.1L66,73.9V79.4z"/>
                                <path d="M53.4,54.9v1.9c0,0.4-0.2,0.8-0.4,1c-0.3,0.2-0.6,0.4-1,0.4h-2.7c-0.4,0-0.8-0.2-1-0.4c-0.2-0.3-0.4-0.6-0.4-1v-1.9
                                    c-2.6-0.5-5.1-1.3-7.4-2.4c-1.9-0.8-3.6-1.8-5.4-2.9v11.7c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5h27.5
                                    c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2V49.7c-1.7,1-3.4,2-5.1,2.7C58.4,53.5,55.9,54.3,53.4,54.9z"/>
                                <path d="M64.4,41.1h-8v-2.3c0-0.4-0.2-0.7-0.4-0.9c-0.3-0.3-0.6-0.4-0.9-0.4H46c-0.4,0-0.7,0.2-0.9,0.4c-0.3,0.3-0.4,0.6-0.4,0.9
                                    v2.3h-8c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.2V47c2.1,1.4,4.1,2.7,6.3,3.6c2.1,0.9,4.2,1.7,6.4,2.2v-1.7
                                    c0-0.4,0.2-0.8,0.4-1c0.3-0.2,0.6-0.4,1-0.4h2.7c0.4,0,0.8,0.2,1,0.4c0.2,0.3,0.4,0.6,0.4,1v1.7c2.3-0.5,4.4-1.2,6.6-2.2
                                    c2.2-1,4.2-2.2,6.2-3.5v-4.2c0-0.5-0.2-0.9-0.5-1.2C65.3,41.3,64.9,41.1,64.4,41.1z M54.3,39.4V41h-7.5v-1.7v-0.1h0.1h7.2
                                    c0.1,0,0.1,0.1,0.2,0V39.4z"/>
                                <path d="M51.7,56.4v-0.1v-4.9c0.1,0,0.1,0-0.1-0.1h-2.3h-0.1v0.1v4.9v0.1h0.1h2.3H51.7z"/>
                                <path d="M16,27.6c0.1,0.1,0.2,0,0.3,0c0.5-0.4,1.1-0.8,1.5-1.3c0.9-1,1.5-2.3,1.8-3.4c0.3-1.2,0.2-2.5-0.4-3.4
                                    c-0.2-0.3-0.3-0.6-0.6-0.8c-0.7-0.7-1.5-1-2.4-1c-0.8,0-1.8,0.3-2.5,0.9c-0.3,0.3-0.6,0.6-0.8,0.9c-0.5,0.8-0.7,1.9-0.5,3
                                    c0.1,1,0.6,2.2,1.4,3.2C14.3,26.4,15.1,27.1,16,27.6z"/>
                                <path d="M84.3,27.6c0.1,0.1,0.2,0,0.3,0c0.5-0.4,1.1-0.8,1.5-1.3c0.9-1,1.5-2.3,1.8-3.4c0.3-1.2,0.2-2.5-0.4-3.4
                                    c-0.2-0.3-0.3-0.6-0.6-0.8c-0.7-0.7-1.5-1-2.4-1c-0.8,0-1.8,0.3-2.5,0.9c-0.3,0.3-0.6,0.6-0.8,0.9c-0.5,0.8-0.7,1.9-0.5,3
                                    c0.1,1,0.6,2.2,1.4,3.2C82.7,26.4,83.5,27.1,84.3,27.6z"/>
                                <path d="M16,95.8c0.1,0.1,0.2,0,0.3,0c0.5-0.4,1.1-0.8,1.5-1.3c0.9-0.9,1.5-2.3,1.8-3.4c0.3-1.2,0.2-2.5-0.4-3.4
                                    c-0.2-0.3-0.3-0.6-0.6-0.8c-0.7-0.7-1.5-1-2.4-1c-0.8,0-1.8,0.3-2.5,0.9c-0.3,0.3-0.6,0.6-0.8,0.9c-0.5,0.8-0.7,1.9-0.5,3
                                    c0.1,1,0.6,2.2,1.4,3.2C14.3,94.6,15.1,95.3,16,95.8z"/>
                                <path d="M3.1,13.1c0,0.1,0,0.1,0,0.2c0,1.5,1.5,2.9,3.3,2.9s3.2-1.2,3.2-2.8c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2
                                    c0,1.5,1.4,2.8,3.2,2.8s3.2-1.2,3.2-2.8c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8s3.2-1.2,3.2-2.8
                                    c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8c1.8,0,3.2-1.2,3.2-2.8c0-0.1,0-0.2,0-0.3v-0.9h-26V13.1z"/>
                                <path d="M28.4,25.7h-3.1V18c-1.2,0-2.4-0.4-3.3-1c-0.1,0.1-0.3,0.2-0.5,0.3v6.6h-1.1c-0.1,0.3-0.2,0.7-0.4,1
                                    c-0.4,0.6-0.8,1.2-1.3,1.9c0,0.1,0,0.2-0.1,0.2c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.2-0.6,0.3-0.9,0.3
                                    c-0.4,0-0.7-0.1-1-0.3c-0.5-0.3-1-0.6-1.4-1c-0.5-0.4-0.9-0.8-1.2-1.2c-0.4-0.6-0.8-1.2-1.1-1.9c-0.1-0.1-0.2-0.3-0.2-0.4H9.4v-6.9
                                    c-0.9,0.6-2.1,1-3.3,1H6v7.7H3.3c-0.4,0-0.7,0.6-0.7,1.5c0,0.9,0.3,1.7,0.7,1.7h25.1c0.4,0,0.7-0.7,0.7-1.7
                                    C29.1,26.5,28.8,25.7,28.4,25.7z"/>
                                <path d="M7.2,3.9h17.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H7.2c-0.4,0-0.8,0.3-0.7,0.7C6.5,3.6,6.8,3.9,7.2,3.9z"/>
                                <polygon points="26,4.9 6.4,4.9 3.7,10.2 28.5,10.2 	"/>
                                <path d="M71.7,29h24.7c0.4,0,0.7-0.7,0.7-1.7c0-0.9-0.3-1.7-0.7-1.7h-2.7V18c-1.2,0-2.4-0.4-3.3-1c-0.1,0.1-0.3,0.2-0.5,0.3v6.6
                                    h-1.1c-0.1,0.3-0.2,0.7-0.4,1c-0.4,0.6-0.8,1.2-1.3,1.9c0,0.1,0,0.2-0.1,0.2c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-1,0.7
                                    c-0.3,0.2-0.6,0.3-0.9,0.3c-0.4,0-0.7-0.1-1-0.3c-0.5-0.3-1-0.6-1.4-1c-0.5-0.4-0.9-0.8-1.2-1.2c-0.4-0.6-0.8-1.2-1.1-1.9
                                    c-0.1-0.1-0.2-0.3-0.2-0.4h-1.2v-6.9c-0.9,0.6-2.1,1-3.3,1h-0.1v7.7h-2.7c-0.4,0-0.7,0.6-0.7,1.5C71,28.3,71.3,29,71.7,29z"/>
                                <path d="M75.6,3.9h17.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H75.6c-0.4,0-0.8,0.3-0.7,0.7C74.8,3.6,75.1,3.9,75.6,3.9z"/>
                                <polygon points="94.4,4.9 74.7,4.9 72,10.2 96.8,10.2 	"/>
                                <path d="M71.5,12.2v0.9c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8c1.8,0,3.2-1.2,3.2-2.8c0,1.5,1.5,2.9,3.3,2.9s3.2-1.2,3.2-2.8
                                    c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8s3.2-1.2,3.2-2.8c0-0.1,0-0.1,0-0.2H91c0,0.1,0,0.1,0,0.2
                                    c0,1.5,1.4,2.8,3.2,2.8c1.8,0,3.2-1.2,3.2-2.8c0-0.1,0-0.2,0-0.3v-0.9H71.5z"/>
                                <polygon points="3.7,78.5 28.5,78.5 26,73.1 6.4,73.1 	"/>
                                <path d="M28.4,94.1h-3.1v-7.7c-1.2,0-2.4-0.4-3.3-1c-0.1,0.1-0.3,0.2-0.5,0.3v6.6h-1.1c-0.1,0.3-0.2,0.7-0.4,1
                                    c-0.4,0.6-0.8,1.2-1.3,1.9c0,0.1,0,0.2-0.1,0.2c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.2-0.6,0.3-0.9,0.3
                                    c-0.4,0-0.7-0.1-1-0.3c-0.5-0.3-1-0.6-1.4-1c-0.5-0.4-0.9-0.8-1.2-1.2c-0.4-0.6-0.8-1.2-1.1-1.9c-0.1-0.1-0.2-0.3-0.2-0.4H9.4v-6.9
                                    c-0.9,0.6-2.1,1-3.3,1H6v7.7H3.3c-0.4,0-0.7,0.7-0.7,1.7c0,0.9,0.3,1.7,0.7,1.7h25.1c0.4,0,0.7-0.8,0.7-1.8S28.8,94.1,28.4,94.1z"
                                    />
                                <path d="M3.2,81.5c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8s3.2-1.2,3.2-2.8c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2
                                    c0,1.5,1.4,2.8,3.2,2.8s3.2-1.2,3.2-2.8c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8s3.2-1.2,3.2-2.8
                                    c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8c1.8,0,3.2-1.2,3.2-2.8c0-0.1,0-0.2,0-0.3v-0.8H3.2V81.5z"/>
                                <path d="M25.1,72.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H7.2c-0.4,0-0.8,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H25.1z"/>
                                <polygon points="72,78.5 96.8,78.5 94.4,73.1 74.7,73.1 	"/>
                                <path d="M71.5,81.5c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8c1.8,0,3.2-1.2,3.2-2.8c0,1.5,1.5,2.8,3.3,2.8s3.2-1.2,3.2-2.8
                                    c0-0.1,0-0.1,0-0.2h0.1c0,0.1,0,0.1,0,0.2c0,1.5,1.4,2.8,3.2,2.8s3.2-1.2,3.2-2.8c0-0.1,0-0.1,0-0.2H91c0,0.1,0,0.1,0,0.2
                                    c0,1.5,1.4,2.8,3.2,2.8c1.8,0,3.2-1.2,3.2-2.8c0-0.1,0-0.2,0-0.3v-0.8H71.5V81.5z"/>
                                <path d="M93.4,72.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H75.6c-0.4,0-0.8,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H93.4z"/>
                                <path d="M96.4,94.1h-2.7v-7.7c-1.2,0-2.4-0.4-3.3-1c-0.1,0.1-0.3,0.2-0.5,0.3v6.6h-1.1c-0.1,0.3-0.2,0.7-0.4,1
                                    c-0.4,0.6-0.8,1.2-1.3,1.9c0,0.1,0,0.2-0.1,0.2c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.2-0.6,0.3-0.9,0.3
                                    c-0.4,0-0.7-0.1-1-0.3c-0.5-0.3-1-0.6-1.4-1c-0.5-0.4-0.9-0.8-1.2-1.2c-0.4-0.6-0.8-1.2-1.1-1.9c-0.1-0.1-0.2-0.3-0.2-0.4h-1.2
                                    v-6.9c-0.9,0.6-2.1,1-3.3,1h-0.1v7.7h-2.7c-0.4,0-0.7,0.7-0.7,1.7c0,0.9,0.3,1.7,0.7,1.7h25.1c0.4,0,0.3-0.8,0.3-1.8
                                    S96.8,94.1,96.4,94.1z"/>
                                <path d="M84.3,95.8c0.1,0.1,0.2,0,0.3,0c0.5-0.4,1.1-0.8,1.5-1.3c0.9-0.9,1.5-2.3,1.8-3.4c0.3-1.2,0.2-2.5-0.4-3.4
                                    c-0.2-0.3-0.3-0.6-0.6-0.8c-0.7-0.7-1.5-1-2.4-1c-0.8,0-1.8,0.3-2.5,0.9c-0.3,0.3-0.6,0.6-0.8,0.9c-0.5,0.8-0.7,1.9-0.5,3
                                    c0.1,1,0.6,2.2,1.4,3.2C82.7,94.6,83.5,95.3,84.3,95.8z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Upload Branches</h6></a>
                    </li>
                    <li class="border rounded p-3" v-b-modal.headre_upload_clients>
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="22.4 0.7 100 100" style="enable-background:new 22.4 0.7 100 100;" xml:space="preserve">
                                <path d="M47.5,56.5L47.5,56.5c0,7.6,17.2,7.9,16.9,0c-0.3-1.6,4.2-6.9,5.1-9.6c1.5-2.4,2.4-6.9,0.6-9.5c-1.1-1.5-0.6-2-0.6-3.9
                                    c0-15.4-27-15.4-27,0c0,1.6,0.3,2.5-0.4,3.7c-1.8,2.8-1.2,7.1,0.4,9.8C43.4,49.6,47.7,55.1,47.5,56.5z"/>
                                <path d="M58.3,64.6h-5.6c-0.9,0-1.5,0.7-1.5,1.5v2.5c0,0.9,0.7,1.5,1.5,1.5h1l-1.8,9.4h7.3l-1.8-9.4h0.9c0.9,0,1.5-0.7,1.5-1.5
                                    v-2.5C59.8,65.3,59.1,64.6,58.3,64.6z"/>
                                <path d="M78.7,66.3c-3.6-1.3-7.1-3-10.2-5.1l-7,18.4H86C86,69.1,86.1,68.7,78.7,66.3z"/>
                                <path d="M31.7,67.6c-7.9,3-6.1,2.6-6.8,12h24.7l-6.8-17.6C39.3,64.2,35.6,66.1,31.7,67.6z"/>
                                <path d="M114,68.9c-3.3-1-6.5-2.4-9.5-4.1L99,79.6h20.9C120,71.1,120,70.8,114,68.9z"/>
                                <path d="M96.4,67.5h-4.5c-0.7,0-1.3,0.6-1.3,1.2c0,0,0,0,0,0v2c0,0.7,0.6,1.2,1.2,1.2h0.8l-1.5,7.5h5.9L95.7,72h0h0.8
                                    c0.7,0,1.2-0.6,1.3-1.2v-2C97.7,68.1,97.1,67.5,96.4,67.5z"/>
                                <path d="M101.3,61c-0.2-1.3,3.4-5.5,4.1-7.7c1.2-1.9,1.9-5.6,0.5-7.6c-0.8-1.2-0.5-1.6-0.5-3.2c0-12.4-21.7-12.4-21.7,0
                                    c0,1.3,0.3,2-0.3,2.9c-1.5,2.3-1,5.7,0.4,7.8c0.6,2.1,4.1,6.6,4,7.7l0,0C87.7,67.2,101.5,67.4,101.3,61z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Upload Clients</h6></a>
                    </li>
                </ul>
            </div>
        </b-modal>

        <!-- Upload Subsidiaries -->
        <b-modal id="headre_upload_subsidiaries" title="Upload Subsidiaries" hide-footer>
            <ValidationObserver v-slot="{ passes }">
                <form @submit.prevent="passes(subidiariesSubmit)" id="single-form">
                    <div class="d-flex flex-column flex-md-row justify-content-start align-items-center">
                        <ValidationProvider name="scsv_excel_file" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !subsidiaries.csvExcelFile,'success':subsidiaries.csvExcelFile}">
                                <div class="custom-file">
                                    <b-form-file
                                        v-model="subsidiaries.csvExcelFile"
                                        placeholder="Select Excel/CSV*"
                                        drop-placeholder="Drop file here..."
                                        accept=".xls,.csv"
                                    ></b-form-file>
                                    <i class="fa fa-times" v-if="errors.length && !subsidiaries.csvExcelFile"></i>
                                    <i class="fa fa-check" v-if="subsidiaries.csvExcelFile"></i>
                                </div>
                            </div>
                        </ValidationProvider>
                        <div class="form-group">
                            <b-button variant="outline-primary ml-3" size="lg">Download Template</b-button>
                        </div>
                    </div>
                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                        <b-button type="submit" variant="primary btn-mw" size="lg">Add Subsidiaries</b-button>
                        <b-button @click="$bvModal.hide('headre_upload_subsidiaries')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                    </div>
                </form>
            </ValidationObserver>
        </b-modal>

        <!-- Upload Branches -->
        <b-modal id="headre_upload_branches" title="Upload Branches" hide-footer>
            <ValidationObserver v-slot="{ passes }">
                <form @submit.prevent="passes(branchSubmit)" id="branches-form">
                    <div class="d-flex flex-column flex-md-row justify-content-start align-items-center">
                        <ValidationProvider name="bcsv_excel_file" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !branches.csvExcelFile,'success':branches.csvExcelFile}">
                                <div class="custom-file">
                                    <b-form-file
                                        v-model="branches.csvExcelFile"
                                        placeholder="Select Excel/CSV*"
                                        drop-placeholder="Drop file here..."
                                        accept=".xls,.csv"
                                    ></b-form-file>
                                    <i class="fa fa-times" v-if="errors.length && !branches.csvExcelFile"></i>
                                    <i class="fa fa-check" v-if="branches.csvExcelFile"></i>
                                </div>
                            </div>
                        </ValidationProvider>
                        <div class="form-group">
                            <b-button variant="outline-primary ml-1"  size="lg">Download Template</b-button>
                        </div>
                    </div>
                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                        <b-button type="submit" variant="primary btn-mw" size="lg">Add Branch</b-button>
                        <b-button @click="$bvModal.hide('headre_upload_branches')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                    </div>
                </form>
            </ValidationObserver>
        </b-modal>

        <!-- Upload Clients -->
        <b-modal id="headre_upload_clients" title="Upload Clients" hide-footer>
            <ValidationObserver v-slot="{ passes }">
                <form @submit.prevent="passes(clientSubmit)" id="client-form">
                    <div class="d-flex flex-column flex-md-row justify-content-start align-items-center">
                        <ValidationProvider name="client_csv_file" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !clients.csvExcelFile,'success':clients.csvExcelFile}">
                                <div class="custom-file">
                                    <b-form-file
                                        v-model="clients.csvExcelFile"
                                        placeholder="Select Excel/CSV*"
                                        drop-placeholder="Drop file here..."
                                        accept=".xls,.csv"
                                    ></b-form-file>
                                    <i class="fa fa-times" v-if="errors.length && !clients.csvExcelFile"></i>
                                    <i class="fa fa-check" v-if="clients.csvExcelFile"></i>
                                </div>
                            </div>
                        </ValidationProvider>
                        <div class="form-group">
                            <b-button variant="outline-primary ml-1 btn-mw"  size="lg">Download Template</b-button>
                        </div>
                    </div>
                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                        <b-button type="submit" variant="primary btn-mw" size="lg">Add Client</b-button>
                        <b-button size="lg" variant="outline-secondary mt-3 btn-mw" @click="$bvModal.hide('headre_upload_clients')">Cancel</b-button>
                    </div>
                </form>
            </ValidationObserver>
        </b-modal>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name: 'BusinessModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect')
    },data(){
        return{
            singleBusinessDatas:{
                businessName:'',
                owner:'',
                businessEmail:'',
                phoneNumber:'',
                country:null
            },

            countrysOptions: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            subBisnessData:{
                subbusinessName:'',
                parentBusiness:null,
                subOwner:'',
                subbusinessEmail:'',
                subphoneNumber:'',
                country:null
            },

            businessnameOptions:[
                {
                    id: 1,
                    name:'PayAngel Group',
                },
                {
                    id: 2,
                    name:'Thememakker Group',
                }
            ],

            subsidiaries:{
                csvExcelFile:null
            },

            branches:{
                csvExcelFile:null
            },

            clients:{
                csvExcelFile:null
            },
        }
    },methods:{
        //single business submit form data
        singleBusinessForm(){
            this.$bvModal.hide('headre_add_single');
        },

        //sub business submit form data
        subBusinessSubmit(){
            this.$bvModal.hide('headre_add_sub_business');  
        },

        //upload Subsidiaries files submit
        subidiariesSubmit(){
            this.$bvModal.hide('headre_upload_subsidiaries'); 
        },

        //upload Branch files submit
        branchSubmit(){
            this.$bvModal.hide('headre_upload_branches');
        },

        //upload Client files submit
        clientSubmit(){
            this.$bvModal.hide('headre_upload_clients'); 
        }
    }
}
</script>
